// import Login from '../components/auth/Login';

const Home = () => {
	return (
		<>
			<h1>Welcome to Babiya Geomatics Civils Trading</h1>{' '}
			<h2>Our Tender Management system</h2>
			{/* <Login /> */}
		</>
	);
};

export default Home;
