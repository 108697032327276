const About = () => {
	return (
		<>
			<h1>
				Ba Biya Geomatics & Civils Trading Online Tender Management System
			</h1>
		</>
	);
};

export default About;
