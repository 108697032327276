import './footer.css';
const Footer = () => {
	return (
		<>
			<footer className='main-footer'>
				<p>
					Ba Biya Geomatics & Civils Trading Online Tender Management System
				</p>
				<small>All Rights Reserved @2021</small>
			</footer>
		</>
	);
};

export default Footer;
